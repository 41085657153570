import React from 'react';
import './email-container.scss';
import { useEmailContainer } from '../../../hooks/survey/use-email-container';
import { ReactComponent as PublishIcon } from "../../../assets/images/icons/survey/publish-icon.svg";
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/survey/copy-icon.svg";
import { ReactComponent as BounchedIcon } from "../../../assets/images/icons/survey/bounced-icon.svg";
import StatusColumn from '../../lists-table/status-column';
import { FIX_ERRORS_RESPONDENTS, RESPONDENT_STATUS, INVITATION } from "../../../assets/constants";
import { Box, Typography } from '@mui/material';
import { colorTheme } from '../../../assets/theme';
import { makeStyles } from '@material-ui/core/styles';
import ToggleSwitchValue from '../../form/toogle-switch-value';
import Divider from '@mui/material/Divider';
import PrimaryButton from '../../buttons/primary-button';
import AddButton from '../../buttons/add-button';
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import Fab from "@material-ui/core/Fab";
import TextEditor from "./text-editor";
import DatetimePicker from "./datetime-picker";
import RemindersContainer from "./reminders-container";
import RecurringBox from "./recurring-box";
import * as moment from 'moment';

// Constants
const INPUT_COMPANY = 'INPUT_COMPANY';
const INPUT_SUBJECT = 'INPUT_SUBJECT';

// Menu tabs
// let MENU_TABS = ['Publishing', 'Invitation email', 'Reminder email', 'Download'];
let MENU_TABS = ['Publishing', 'Reminders', 'Sent Emails'];

const useStyles = makeStyles({
    textField: {
        width: '100%',
        maxWidth: '320px',

        '& label': {
            color: colorTheme.theme.text.lightGrey, // Default color
        },
        '& label.Mui-focused': {
            color: colorTheme.theme.transform.darkBlueTwo, // Hovered color
        },
        '& label.Mui-focused': {
            color: colorTheme.theme.transform.darkBlueTwo, // Focused color
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid' + colorTheme.theme.text.lightGrey, // Default color
            opacity: 0.42, // Default opacity
            transition: 'all 0.3s ease-in-out'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid' + colorTheme.theme.transform.darkBlueTwo, // Hovered color
            opacity: 1, // Hovered opacity
            transition: 'all 0.3s ease-in-out'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid' + colorTheme.theme.transform.darkBlueTwo, // Hovered color
            transition: 'none'
        },
        '& .MuiInputBase-input': {
            color: colorTheme.theme.text.mediumGrey, // Default color
        },
        '& .MuiInputBase-input::placeholder': {
            color: colorTheme.theme.text.mediumGrey, // Placeholder color
            opacity: 1, // Placeholder opacity
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#d32f2f !important'
        },
        '& .Mui-error:after': {
            borderBottomColor: '#d32f2f !important'
        },
        '& .Mui-disabled': {
            '&::placeholder': {
                color: colorTheme.theme.text.lightGrey, // Placeholder color
            },
        },
    },
});

// EmailContainer component
function EmailContainer(props) {
    const classes = useStyles(); // Custom styles

    const {
        activeTab,
        currentSurvey,
        invitationFromName,
        invitationSubject,
        respondentStatus,
        anonymousSwitch,
        embeddableSwitch,
        anonymousLink,
        embeddableLink,
        copiedLink,
        publishingSurvey,
        openDialog,
        dividerStyle,
        publishTypeTabs,
        activePublishTypeTab,
        tabsRef,
        handleMenuTabClick,
        handleChange,
        handleAnonymmousSwitch,
        handleEmbeddableSwitch,
        handleCopyButton,
        handleSurveyLaunchClick,
        handleClickOpenDialog,
        handleCloseDialog,
        handleRefreshClick,
        handleDownloadButton,
        extractContent,
        handlePublishTypeTabClick,
        findUserInCognitoUsers
    } = useEmailContainer(props);

    return (
        <Box>
            <Typography variant="h6" sx={{ color: colorTheme.theme.text.mediumGrey, fontSize: '16px', fontWeight: '400' }} mb={2}>Publishing and Emails</Typography>
            <Box className="email-container">
                <Box className="menu flex-column-center">
                    {
                        MENU_TABS.map((tab, index) => {
                            const classes = `menu-tab flex-center ${activeTab === tab ? 'active' : ''}`;
                            return (
                                <Box key={index} className={classes} onClick={() => handleMenuTabClick(tab)}>{tab}</Box>
                            )
                        })
                    }
                </Box>
                <Box className='content'>
                    {
                        activeTab === 'Publishing' &&
                        <>
                            {
                                currentSurvey.status === 'COMPLETED' &&
                                <Box className="publish-tab">
                                    <Typography variant='h5' sx={{ fontSize: '22px', color: colorTheme.theme.transform.black, fontWeight: '500', lineHeight: '24px' }}>Survey Completed</Typography>
                                </Box>
                            }
                            {
                                currentSurvey.status === 'SCHEDULED' &&
                                <Box className="publish-tab">
                                    <Typography variant='h5' sx={{ fontSize: '22px', color: colorTheme.theme.transform.black, fontWeight: '500', lineHeight: '24px' }}>Survey Scheduled</Typography>
                                </Box>
                            }
                            {/* {
                                currentSurvey.status === 'LIVE' && currentSurvey.anonynmous &&
                                <Box className='used-invitation-contanier'>
                                    <label> This survey did not use email </label>
                                </Box>
                            } */}
                            {
                                currentSurvey.status === 'LIVE' &&
                                <Box className="publish-tab active">
                                    <Box className="survey-status">
                                        <Box className="left" sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                            <Typography variant='h5' sx={{ fontSize: '22px', color: colorTheme.theme.transform.black, fontWeight: '500', lineHeight: '24px' }}>Survey Published</Typography>
                                            <Typography variant='body2' sx={{ color: colorTheme.theme.text.mediumGrey }}>By: {findUserInCognitoUsers(currentSurvey.publishedBy)}</Typography>
                                        </Box>
                                        <Box className='action-buttons' >
                                            <Fab variant="extended" className="action" onClick={handleClickOpenDialog}>finish</Fab>
                                        </Box>
                                        <Box className="right number-big">
                                            {
                                                currentSurvey.endDate &&
                                                <>
                                                    <Box className="text">Days Left</Box>
                                                    <Box className="number">
                                                        {moment(currentSurvey.endDate).diff(moment.now(), 'days')}
                                                    </Box>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                    <Box className='publish-container'>
                                        <Box className="respondent-status flex-row-center">
                                            <Box className="respondent-total number-big">
                                                <Typography variant='h4' sx={{ fontSize: '48px', fontFamily: 'Rubik', fontWeight: '500', color: colorTheme.theme.text.darkGrey, lineHeight: '56px' }}>{currentSurvey.respondents ? currentSurvey.respondents.data.length : 0}</Typography>
                                                <Typography variant='body2' sx={{ fontSize: '12px', color: colorTheme.theme.text.lightGrey, textTransform: 'uppercase' }}>Respondent{currentSurvey.respondents && currentSurvey.respondents.data.length !== 1 && 's'}</Typography>
                                            </Box>
                                            <Box className="bounced">
                                                <span className="bounced-row flex-row-center">
                                                    <BounchedIcon className="email-bounced-icon" />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                                        <Typography variant='body1' sx={{ color: colorTheme.theme.text.mediumGrey }}>Bounced: {respondentStatus[RESPONDENT_STATUS.EMAIL_BOUNCED]}</Typography>
                                                        <Fab variant="extended" className="resolve" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)}>Resolve</Fab>
                                                    </Box>
                                                </span>
                                            </Box>
                                            <Box className="stats">
                                                {currentSurvey && currentSurvey.respondents && currentSurvey.respondents.data && (
                                                    <>
                                                        <StatusColumn status={'NOT-STARTED'} />
                                                        <Typography variant='body2'>
                                                            {currentSurvey.respondents.data.length - (respondentStatus[RESPONDENT_STATUS.SUBMITTED] + respondentStatus[RESPONDENT_STATUS.STARTED])}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {((currentSurvey.respondents.data.length - (respondentStatus[RESPONDENT_STATUS.SUBMITTED] + respondentStatus[RESPONDENT_STATUS.STARTED])) / currentSurvey.respondents.data.length * 100).toFixed(0)}%
                                                        </Typography>
                                                        <StatusColumn status={'STARTED'} />
                                                        <Typography variant='body2'>
                                                            {respondentStatus[RESPONDENT_STATUS.STARTED]}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {(respondentStatus[RESPONDENT_STATUS.STARTED] / currentSurvey.respondents.data.length * 100).toFixed(0)}%
                                                        </Typography>
                                                        <StatusColumn status={'SUBMITTED'} />
                                                        <Typography variant='body2'>
                                                            {respondentStatus[RESPONDENT_STATUS.SUBMITTED]}
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {(respondentStatus[RESPONDENT_STATUS.SUBMITTED] / currentSurvey.respondents.data.length * 100).toFixed(0)}%
                                                        </Typography>
                                                    </>
                                                )}
                                            </Box>

                                        </Box>

                                    </Box>
                                </Box>

                            }
                            {
                                currentSurvey.status === 'DRAFT' &&
                                <Box className="publishing-type" sx={{ position: 'relative', display: 'flex', gap: '38px', paddingBottom: '5px' }} ref={tabsRef}>
                                    {
                                        publishTypeTabs.map((tab, index) => (
                                            <Box key={index} className={`publish-type-tab flex-center ${activePublishTypeTab === tab.type ? 'active' : ''}`}
                                                onClick={() => handlePublishTypeTabClick(tab.type)}
                                                sx={{ gap: '5px', cursor: 'pointer' }}
                                            >
                                                {tab.icon}
                                                <Typography className='tab-text' sx={{ fontSize: '14px', fontWeight: 500, textTransform: 'uppercase', color: colorTheme.theme.text.lightGrey }}>
                                                    {tab.type}
                                                </Typography>
                                            </Box>
                                        ))
                                    }
                                    <Divider style={{
                                        border: 'none',
                                        height: '3px',
                                        position: 'absolute',
                                        bottom: '0px',
                                        backgroundColor: colorTheme.theme.transform.darkBlueTwo,
                                        transition: 'width 0.3s ease-in-out, left 0.3s ease-in-out',
                                        ...dividerStyle
                                    }} />
                                </Box>
                            }
                            {
                                <Box className={`tab-container ${currentSurvey.status === 'LIVE' ? 'disabled-block' : ''}`} sx={{ marginTop: '22px' }}>
                                    {
                                        activePublishTypeTab === 'via email' &&
                                        <>
                                            {currentSurvey.status === 'DRAFT' || currentSurvey.status === 'LIVE' ?
                                                <>
                                                    <Box className="form" sx={{ gap: '20px' }}>
                                                        <TextField
                                                            className='input'
                                                            classes={{ root: classes.textField }}
                                                            label="From"
                                                            value={invitationFromName.value ? invitationFromName.value : ''}
                                                            onChange={(e) => handleChange(e.target.value, INPUT_COMPANY)}
                                                            placeholder='survey@transforminsight.com'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled
                                                        />
                                                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
                                                            <TextField className='input' label="To"
                                                                classes={{ root: classes.textField }}
                                                                placeholder={currentSurvey.respondents && currentSurvey.respondents.data.length > 0 ? 'Respondent list' : 'Respondent list not added'}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                disabled
                                                            />
                                                            <AddButton
                                                                Icon={null} text={currentSurvey.respondents && currentSurvey.respondents.data.length > 0 ? 'Manage respondents' : 'Add list'} onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)} hoverColor={colorTheme.theme.transform.darkBlue}
                                                            />
                                                        </Box>
                                                        <TextField className='input' label="Subject"
                                                            classes={{ root: classes.textField }}
                                                            value={invitationSubject.value ? invitationSubject.value : ''}
                                                            onChange={(e) => handleChange(e.target.value, INPUT_SUBJECT)}
                                                            error={invitationSubject.error}
                                                            helperText={invitationSubject.errorMessage}
                                                            placeholder='Survey Conduct'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box className="edit-email">
                                                        <TextEditor source={INVITATION} />
                                                    </Box>
                                                </> : <></>
                                            }
                                            {
                                                currentSurvey.status !== 'DRAFT' && (currentSurvey.anonynmous === false || currentSurvey.anonynmous === undefined) &&
                                                <Box className='used-invitation-contanier'>
                                                    <label> Sent invitation email: </label>
                                                    <TextareaAutosize className='used-invitation' variant='filled' value={extractContent(currentSurvey.invitationEmailText)} disabled />
                                                </Box>
                                            }
                                        </>
                                    }
                                    {
                                        activePublishTypeTab === 'anonymous' &&
                                        <Box className='link-container'>
                                            <Box className='anon-contanier'>
                                                <Typography variant='body' className='text' sx={{ color: colorTheme.theme.text.lightGrey }}>Link:</Typography>
                                                <TextField className='anonymous-link' variant='standard' size='small' disabled value={anonymousLink} />
                                                <Fab variant="extended" className="copy" size="small" onClick={e => handleCopyButton(anonymousLink)}>
                                                    <CopyIcon className="copy-icon" />
                                                    Copy Link
                                                </Fab>
                                            </Box>
                                            <Box className='embeded-contanier'>
                                                <Box sx={{ alignSelf: 'flex-start', marginLeft: '0px' }}>
                                                    <ToggleSwitchValue label='Embeded HTML' value={embeddableSwitch} onChange={handleEmbeddableSwitch} />
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', opacity: embeddableSwitch ? '1' : '0.45', pointerEvents: embeddableSwitch ? 'initial' : 'none' }}>
                                                    <TextField className='embeded-link' variant='outlined' multiline={true} disabled value={embeddableLink}> </TextField>
                                                    <Fab variant="extended" className="copy" size="small" onClick={e => handleCopyButton(embeddableLink)}>
                                                        <CopyIcon className="copy-icon" />
                                                        Copy code
                                                    </Fab>
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        currentSurvey.status === "DRAFT" || currentSurvey.status === "LIVE" ?
                                            <>
                                                <DatetimePicker />
                                                {
                                                    currentSurvey.status === 'DRAFT' && activePublishTypeTab === 'via email' &&
                                                    <RecurringBox />
                                                }
                                                {
                                                    currentSurvey.status === 'DRAFT' &&
                                                    <Box className="publish flex-center">
                                                        {publishingSurvey && <CircularProgress className='loader' />}
                                                        {!publishingSurvey &&
                                                            <PrimaryButton text={currentSurvey.startImmediately ? 'Publish Survey' : 'Schedule Survey'} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} Icon={PublishIcon} onClick={handleSurveyLaunchClick} />
                                                        }
                                                    </Box>
                                                }
                                            </> : <></>
                                    }
                                </Box>
                            }
                        </>
                    }
                    {
                        activeTab === 'Download' &&
                        <Box className='download-page'>
                            {
                                currentSurvey.status !== 'DRAFT' ?
                                    <>
                                        <label>Download questions and responses of published survey</label>
                                        <Button variant="contained" size="medium" color="primary" className="download-button" onClick={e => handleDownloadButton()}>
                                            Download survey data
                                        </Button>
                                    </>
                                    :
                                    <Box>Survey isnt published</Box>
                            }
                        </Box>
                    }
                    {
                        activeTab === 'Reminders' &&
                        <Box className='reminder-page'>
                            {
                                currentSurvey.status !== 'DRAFT' ? <RemindersContainer /> : <label> Survey is not published yet. You don't have to send reminders </label>
                            }
                        </Box>
                    }
                </Box>
                <ConfirmSurveyCloseDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
            </Box>
        </Box>
    )
}

// Function to render dialog box for closing survey
function ConfirmSurveyCloseDialog({ openDialog, handleCloseDialog }) {
    return (
        <Dialog
            open={openDialog}
            onClose={() => handleCloseDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Close Survey?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Once the survey is closed, respondents will not be able to open the survey.
                    You will be able to undo this action later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleCloseDialog(true)} color="primary" autoFocus>
                    Close Survey
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EmailContainer;
