import React from 'react';
import ListsTable from '../../../components/lists-table/lists-table';
import UploadList from '../../lists-table/upload-list';
import TitleWithIcon from "../../title/title-icon";
import { useAdditionalLists } from '../../../hooks/survey/use-additional-lists';
import { CUSTOM_LIST_FILE } from "../../../assets/constants";
import { Box, Typography } from '@mui/material';
import { ReactComponent as PaperIcon } from '../../../assets/images/title/paper.svg';
import { colorTheme } from '../../../assets/theme';
import { ReactComponent as EmptyRespondentsIcon } from "../../../assets/images/table/empty-lists.svg";

// CustomLists component
function AdditionalLists() {

    const {
        customLists,
        saveColumn,
        handleDataUpdate,
        handleUploadedData,
        handleTitleUpdate,
        handleDelete } = useAdditionalLists(); // Get additional lists and functions from additional lists hook

    return (
        <Box>
            <TitleWithIcon title='Manage Additional Lists' Icon={PaperIcon} fontSize={'18px'} />
            <Box display={"flex"} justifyContent={"space-between"} alignItems={'flex-end'}>
                <Typography variant="body2" sx={{ color: colorTheme.theme.text.lightGrey }} mt={1}>
                    Upload your list of respondents here. Please ensure the list includes the required columns: Name and Email. <br />
                    Please use an Excel file format (.xlsx or .xls) or CSV (.csv) to ensure proper data processing.
                </Typography>
                <UploadList 
                    type={CUSTOM_LIST_FILE} listId={null} file={null}
                    handleUploadedData={(data, file) => handleUploadedData(data, null, file)} id={`custom-list`}
                    handleDelete={handleDelete} errorCount={null}
                />  
            </Box>
            <Box className='custom-list-container'>
                {
                    customLists.length > 0 ?
                        (customLists.map((list, index) => (
                            <Box className="custom-list" key={`custom-list-${index}`}>
                                <ListsTable data={list.data}
                                    columns={list.columns}
                                    updateData={(data) => handleDataUpdate(data, list.id)}
                                    title={list.title}
                                    updateTitle={(title, lists) => handleTitleUpdate(title, list.id, lists)}
                                    type={CUSTOM_LIST_FILE}
                                    listId={list.id}
                                    handleDelete={handleDelete}
                                    emptyTableTitle={'Hey, it looks like you haven’t added any additional lists yet.'}
                                    emptyTableDescription={'Upload your list to organize your data and simplify your survey creation process.'}
                                    EmptyTableIcon={EmptyRespondentsIcon}
                                    paginationText={'Rows per page:'}
                                    saveColumn={(newColumnName) => saveColumn(newColumnName, list.id)}
                                />
                            </Box>
                        ))) : 
                        <ListsTable data={ []}
                            columns={[]}
                            updateData={null}
                            deleteData={null}
                            title='Respondents'
                            type={CUSTOM_LIST_FILE}
                            handleDelete={null}
                            emptyTableTitle={'Hey, it looks like you haven’t added any additional lists yet.'}
                            emptyTableDescription={'Upload your list to organize your data and simplify your survey creation process.'}
                            EmptyTableIcon={EmptyRespondentsIcon}
                        />
                }
            </Box>
        </Box>
    );
}

export default AdditionalLists;
