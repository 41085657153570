import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { deleteCurrentSurvey, copyCurrentSurvey, getSurveys, setCurrentSurvey, assignUserToSurvey, getAssignedUsers, getOrganizationSurveys } from "../../redux/actions";

// Custom hook to enhance survey view functionality
export function useSurveyView(props, numberOfSurveyToShow) {
    const history = useHistory(); // History
    const dispatch = useDispatch(); // Dispatch
    const surveysFetched = useSelector(state => state.surveysFetched); // Surveys fetched
    const surveys = useSelector(state => state.surveys); // Surveys
    const userData = useSelector(state => state.userData); // User data
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Cognito users 
    const assignedUsers = useSelector(state => state.assignedUsers); // Assigned users
    const [surveyMenuOpened, setSurveyMenuOpened] = useState(null); // State to store the opened survey menu
    const [anchorEl, setAnchorEl] = useState(null); // State to store the anchor element
    const [dialogOpen, setDialogOpen] = useState(false); // State to store if the dialog is open
    const [invitations, setInvitations] = useState(); // State to store the invitations
    const [companyName, setCompanyName] = useState(); // State to store the company name
    const [selectedSurveyName, setSelectedSurveyName] = useState(); // State to store the selected survey name
    const [callerData, setCallerData] = useState(); // State to store the caller data
    const [surveyToDelete, setSurveyToDelete] = useState(null);
    const searchValue = props.searchValue; // Current search value
    const statusSurvey = props.surveyStatus; // Current survey status filter
    const view = props.view; // Current view
    const sortedSurveys = props.sortedSurveys; // Current sorted surveys
    const setOpenModal = props.setOpenModal; // Function to open the modal
    const [displayCount, setDisplayCount] = useState(numberOfSurveyToShow); // State to store the display count
    const [surveysToShow, setSurveysToShow] = useState(surveys); // State to store the surveys to show
    const [isCreateNewSurveyDialogOpen, setCreateNewSurveyDialogOpen] = useState(false); // State to store if the create new survey dialog is open
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State to store if the delete dialog is open
    const [isLoading, setIsLoading] = useState(false); // State to store if the delete action is loading

    // Surveys to show
    useEffect(() => {
        const filteredSurveys = sortedSurveys.filter(survey =>
            (survey.name ? survey.name.toLowerCase().includes(searchValue.toLowerCase()) : (searchValue === ''))
            && (statusSurvey === '' ? true : survey.status === statusSurvey) // Filter by status if surveyStatus is not empty
        );
        setSurveysToShow(filteredSurveys);
        setDisplayCount(filteredSurveys.length > numberOfSurveyToShow ? numberOfSurveyToShow : filteredSurveys.length);
    }, [surveys, searchValue, statusSurvey, sortedSurveys]);

    // Check if the user is logged in
    useEffect(() => {
        Auth.currentUserInfo().then(res => {
            if (Object.entries(res).length === 0) {
                localStorage.clear()
                history.replace('/signin')
            }
        })

        if (!surveysFetched) {
            // dispatch(getSurveys());
            dispatch(getOrganizationSurveys());
            console.log(surveysFetched)
        }
    }, []);

    // Set the caller data
    useEffect(() => {
        if (userData.firstName && userData.lastName) {
            setCallerData({ name: userData.firstName, lastName: userData.lastName })
        }
    }, [userData])

    // Handle the menu click
    const handleMenuClick = (event, surveyId) => {
        const surveyIndex = sortedSurveys.findIndex(survey => survey.id === surveyId);

        setSurveyMenuOpened(surveyIndex);
        setAnchorEl(event.currentTarget);
        setCompanyName(sortedSurveys[surveyIndex].companyName)
        setSelectedSurveyName(sortedSurveys[surveyIndex].name)
        dispatch(getAssignedUsers(sortedSurveys[surveyIndex].id))
    };

    // Handle the menu close
    const handleMenuClose = () => {
        setSurveyMenuOpened(null);
        setAnchorEl(null);
        setCompanyName('')
        setSelectedSurveyName('')
    };

    // Handle the menu item click
    const handleMenuItemClick = (type) => {
        switch (type) {
            case 'Edit':
                if (surveyMenuOpened !== null) {
                    dispatch(setCurrentSurvey(sortedSurveys[surveyMenuOpened]));
                    history.push(`/survey/edit`);
                }
                break;
            case 'Duplicate':
                if (surveyMenuOpened !== null) {
                    dispatch(copyCurrentSurvey(sortedSurveys[surveyMenuOpened]))
                }
                break;
            case 'Preview':
                if(surveyMenuOpened !== null) {
                    window.open(`http://${process.env.REACT_APP_CLIENT_URL}?token=${sortedSurveys[surveyMenuOpened].surveyAccessToken}&preview=true`, "_blank");
                }
                break;
            case 'Add_colaborators':
                setDialogOpen(true)
                break;
            case 'Delete':
                setDeleteDialogOpen(true);
                setSurveyToDelete(sortedSurveys[surveyMenuOpened])
                handleMenuClose();
                break;

            default:
                console.log('list item handler missing');
        }
    };

    // Handle the dialog close
    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    // Handle the delete confirmed
    const deleteConfirmed = () => {
        console.log(surveyToDelete);
        setIsLoading(true);
        if (surveyToDelete !== null) {
            dispatch(deleteCurrentSurvey(surveyToDelete)).then(() => {
                setSurveyToDelete(null);
                setDeleteDialogOpen(false);
                setIsLoading(false);
            })
        }
        if (isLoading) {
            dispatch(getOrganizationSurveys());
        }

    }

    // Handle the invite
    const handleInvite = () => {
        if (invitations && invitations.length > 0) {
            dispatch(assignUserToSurvey(surveyMenuOpened.id, invitations, undefined, undefined, selectedSurveyName, callerData))
            handleDialogClose()
        }
    }

    // Handle the workspace invite
    const handleWorkspaceInvite = (email, name, surname) => {
        let attributes = {}
        if (name && surname) {
            attributes.firstName = name;
            attributes.lastName = surname;
        }
        dispatch(assignUserToSurvey(surveyMenuOpened.id, undefined, email, attributes, selectedSurveyName, callerData, cognitoUsers))
    }

    // Handle the selected rows
    const handleSelectedRows = (selectedRows) => {
        setInvitations(selectedRows)
    }

    // Toggle the create new survey dialog
    const toggleCreateNewSurveyDialog = () => {
        setCreateNewSurveyDialogOpen(!isCreateNewSurveyDialogOpen);
    }

    // Handle the close of the delete dialog
    const handleDeleteDialogClose = () => {
        console.log('close')
        console.log(deleteDialogOpen)
        setDeleteDialogOpen(false);
        console.log(deleteDialogOpen)
    }

    // Return the necessary values
    return {
        isCreateNewSurveyDialogOpen,
        toggleCreateNewSurveyDialog,
        setOpenModal,
        surveysFetched,
        surveys,
        view,
        surveysToShow,
        handleMenuClick,
        sortedSurveys,
        displayCount,
        setDisplayCount,
        anchorEl,
        handleMenuClose,
        handleMenuItemClick,
        dialogOpen,
        handleDialogClose,
        handleInvite,
        handleWorkspaceInvite,
        selectedSurveyName,
        handleSelectedRows,
        assignedUsers,
        companyName,
        deleteConfirmed,
        deleteDialogOpen,
        handleDeleteDialogClose,
        surveyToDelete,
        isLoading
    };
};