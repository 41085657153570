import React from "react";
import './dashboard-container.scss';
import Typography from "@material-ui/core/Typography";
import AppCard from "../../components/app-card/app-card";
import ResourcesCard from "../../components/resources-card/resources-card";
import MyTeammates from "../../components/my-teammates/my-teammates";
import ProtectedComponent from "../../helper/protected-component";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard/home-page-icon.svg";
import { ReactComponent as SurveyLogo } from "../../assets/images/dashboard/survey-logo.svg";
import { ReactComponent as InsightsLogo } from "../../assets/images/dashboard/insights-logo.svg";

const DashboardContainer = () => {
    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <div>
                    <DashboardIcon />
                </div>
                <Typography variant="h2">Transform Overview</Typography>
            </div>
            <div className="dashboard-cards">
                <ProtectedComponent licenses={['surveyLicense']}>
                    <AppCard title="Survey" icon={<SurveyLogo />} description="Create, publish and analyze surveys to gather valuable information." link="/survey" />
                </ProtectedComponent>
                <ProtectedComponent licenses={['insightsLicense']}>
                    <AppCard title="Insights" icon={<InsightsLogo />} description="Insights derived from the surveys and additional digital data sources." link="/insights" />
                </ProtectedComponent>
                <ResourcesCard />
                <ProtectedComponent licenses={[]}>
                    <MyTeammates />
                </ProtectedComponent>
            </div>
        </div>
    );
}

export default DashboardContainer;