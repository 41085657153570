import React from "react";
import { ACTIVE_STATUS_COLUMN } from "../../assets/constants";
import { TableRow, TableCell } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/images/actions/icon-x.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/actions/icon-save-3.svg';
import { ReactComponent as EditIcon } from '../../assets/images/actions/icon-edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/actions/icon-delete.svg';
import { ReactComponent as ConfirmIcon } from '../../assets/images/actions/icon-save-3.svg';
import { emailRegEx } from "../../utils/regex-utils";
import { leftPosition } from '../../utils/sticky-column';
import ListsTableActions from "./lists-table-actions";
import InputField from '../../components/form/input-field-label';
import StatusColumn from './status-column';
import ErrorText from '../info/error-text';

// ListsTableRow component
const ListsTableRow = ({ index, row, state, isEditing, initiateEdit, confirmEdit, cancelEdit, initiateDelete, deletingRow, confirmDelete, cancelDelete, newRespondent, handleNewRespondentChange, respondentList, addingColumn }) => {
    return (
        <TableRow
            key={index}
            sx={{
                '&:last-child td, &:last-child th': { borderBottom: 0 },
            }}
        >
            {state.columns.filter(headCell => headCell.field !== 'tableData' && headCell.field !== 'Status' && state.selectedColumns.includes(headCell.field)).map((headCell, index) => (
                <TableCell
                    key={headCell.field}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sx={{
                        border: 'none',
                        pr: '24px',
                        pt: isEditing ? '0px' : '12px',
                        pb: isEditing ? '0px' : '12px',
                        pl: '0px !important',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        borderBottom: 'none',
                        borderTop: '1px solid #E0E0E0',
                        background: 'white',
                        width: '0',
                        ...(index === 0 && { // If the index is 0, set the padding-left to 24px
                            pl: '24px !important',
                        }),
                        ...(respondentList && { // If the respondentList is true, set 'Name' and 'Survey Progress' columns to sticky
                            ...([ACTIVE_STATUS_COLUMN.field.toLowerCase(), 'name'].includes(headCell.field.toLowerCase()) && { pl: '24px !important ' }),
                            position: [ACTIVE_STATUS_COLUMN.field.toLowerCase(), 'name'].includes(headCell.field.toLowerCase()) ? 'sticky' : 'static',
                            left: leftPosition(state.columns, state.selectedColumns, headCell.field.toLowerCase(), index),
                            zIndex: 1,
                        }),
                    }}
                >
                    {isEditing && headCell.field !== ACTIVE_STATUS_COLUMN.field ? (
                        <InputField
                            value={newRespondent[headCell.field] || ''}
                            label={null}
                            placeholder={headCell.title}
                            textFieldWidth="100%"
                            inputPadding="10px 12px"
                            lineHeight="20px"
                            inputfontSize="14px"
                            boxMarginBottom="0px"
                            labelFontSize="12px"
                            labelMarginBottom="8px"
                            onChange={(e) => handleNewRespondentChange(headCell.field, e.target.value)}
                        />
                    ) : (
                        headCell.field !== 'SURVEY_CONST_ACTIVE_STATUS_COLUMN' 
                        ? (
                          row[headCell.field] === ''
                          ? <ErrorText error={`Missing data`} />
                          : (
                            headCell.field === 'email'
                            ? (
                              emailRegEx.test(row[headCell.field])
                              ? row[headCell.field]
                              : <ErrorText error={row[headCell.field]} />
                            )
                            : row[headCell.field]
                          )
                        )
                        : <StatusColumn status={row[headCell.field]} />
                    )}
                </TableCell>
            ))}
            {
                addingColumn &&
                <TableCell 
                    sx={{
                        border: 'none',
                        pr: '24px',
                        pt: '12px',
                        pb: '12px',
                        pl: '0px !important',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        borderBottom: 'none',
                        borderTop: '1px solid #E0E0E0',
                        background: 'white',
                        width: '0',
                    }}
                >

                </TableCell>
            }
            <TableCell
                sx={{
                    pr: '16px',
                    pt: isEditing ? '10px' : '12px',
                    pb: isEditing ? '10px' : '12px',
                    borderBottom: 'none',
                    borderTop: '1px solid #E0E0E0',
                    background: 'white',
                    position: 'sticky',
                    right: '0px',
                    zIndex: 1,
                }}
                align="right"
            >
                {deletingRow && deletingRow.tableData.id === row.tableData.id ? (
                    <ListsTableActions firstAction={confirmDelete} secondAction={cancelDelete} FirstIcon={ConfirmIcon} SecondIcon={CancelIcon} />
                ) : isEditing ? (
                    <ListsTableActions firstAction={confirmEdit} secondAction={cancelEdit} FirstIcon={SaveIcon} SecondIcon={CancelIcon} />
                ) : (
                    <ListsTableActions firstAction={ () => initiateEdit(row)} secondAction={ () => initiateDelete(row)} FirstIcon={EditIcon} SecondIcon={DeleteIcon} />
                )}
            </TableCell>
        </TableRow>
    );
};

export default ListsTableRow;