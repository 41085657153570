import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Header from "./layout/header";
import NotFoundPage from "./pages/not-found-page";
import NotAuthorized from './pages/not-authorized-page';
import UserManagementContainer from "./users-management-page/user-management-container"
import SettingsContainer from "./pages/settings-page/settings-container"; // Import the SettingsContainer component
import ProfileContainer from './pages/profile-page/profile-container';
import SurveyContainer from './pages/survey-page/survey-container'; // Import the SurveysContainer component
import { colorTheme } from './assets/theme'; // Import the colorTheme object from the theme file
import DashboardContainer from './pages/dashboard-page/dashboard-container'; // Import the DashboardContainer component
import InsightsContainer from './pages/insights-page/insights-container'; // Import the InsightsContainer component
import { useApp } from './hooks/use-app'; // Import the useApp hook
import ProtectedRoute from './helper/protected-route'; // Import the ProtectedRoute component

// Main App component
function App() {
    // Get the userTemplate from the useApp hook
    const { userTemplate } = useApp();

    return (
        // Render the app container with the color theme
        <div className="app-container">
            <MuiThemeProvider theme={colorTheme}>
                <Header />
                <div className="main-container">
                    <Switch>
                        {/*  Route for the home page */}
                        <Route exact path="/" render={() =>
                            userTemplate ?
                                <DashboardContainer /> :
                                <div className='loading-screen'>Loading...</div>
                        } />
                        {/*  Routes for different pages */}
                        {/* <Route exact path="/users" component={UserManagementContainer} /> */}
                        <ProtectedRoute exact path="/settings" component={SettingsContainer} licenses={[]} />
                        <Route exact path="/profile" component={ProfileContainer} />
                        {/*  Protected routes that require licenses */}
                        <ProtectedRoute exact path="/survey" component={SurveyContainer} licenses={['surveyLicense']} />
                        <ProtectedRoute exact path="/survey/edit" component={SurveyContainer} licenses={['surveyLicense']} />
                        <ProtectedRoute exact path="/insights" component={InsightsContainer} licenses={['insightsLicense']} />

                        {/*  Route for unauthorized users */}
                        <Route exact path="/unauthorized" component={NotAuthorized} />
                        {/*  Route for 404 page */}
                        <Route component={NotFoundPage} />

                    </Switch>
                </div>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
