import React, { useState } from 'react';
import { TextField } from '@mui/material';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Box } from '@mui/system';
import { colorTheme } from '../../assets/theme';

const InputField = ({
    type,
    textFieldWidth,
    label,
    value,
    error,
    onChange, // Add this prop
    autoComplete,
    placeholder = '',
    inputPadding = '15px 13px',
    fontSize = '16px',
    lineHeight = 1,
    inputMarginLeft = '0px',
    hideLabel = false,
    labelPosition = '14.5px 16px',
    labelFontSize = '15px',
    onFocus = () => { },
}) => {
    const [inputType, setInputType] = useState(type);
    // Split the label to extract the main text and the asterisk
    const renderLabel = () => {
        if (hideLabel) return '';
        if (label.endsWith('*')) {
            return (
                <>
                    {label.slice(0, -1)}
                    <span style={{ color: colorTheme.theme.transform.darkBlue }}>*</span>
                </>
            );
        }
        return label;
    };


    return (
        <Box style={{ position: 'relative', width: textFieldWidth, marginLeft: inputMarginLeft }}>
            <TextField
                autoFocus={false}
                name="email"
                label={renderLabel()}
                type={inputType}
                fullWidth
                variant="outlined"
                value={value} // Add this prop
                onChange={onChange} // Add this prop
                autoComplete={autoComplete}
                placeholder={placeholder}
                error={error}
                onFocus={onFocus}
                sx={{
                    padding: '0px',
                    width: textFieldWidth, // Conditionally set the width
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            // borderColor: '#1B4972', // Change the outline color
                            // border: '1px solid' // Make the border 1px solid
                        },
                        '&:hover fieldset': {
                            borderColor: '#4F4F4F', // Change the outline color on hover
                            border: '1px solid', // Make the border 1px solid
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: error ? '#E23D32' : '#1B4972', // Keep red or theme color when focused
                            borderWidth: 1,
                        },
                    },
                    '&:hover .MuiInputLabel-root': {
                        color: '#4F4F4F',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: error ? '#E23D32' : '#1B4972', // Keep label color consistent with focus border
                        transform: 'translate(14px, -6px) scale(0.75)',
                    },
                    '.MuiInputLabel-root.MuiInputLabel-shrink': {
                        transform: 'translate(14.5px, -6px) scale(0.75)',
                    },
                    '& .MuiInputLabel-root': {
                        color: colorTheme.theme.text.lightGrey,
                        transform: `translate(${labelPosition}) scale(1)`,
                    },
                    '& .MuiInputBase-input': {
                        padding: inputPadding,
                        lineHeight: lineHeight,
                        height: 'initial',
                        fontSize: fontSize,
                    },
                    '& .MuiFormLabel-root': {
                        lineHeight: '1',
                        fontSize: labelFontSize,
                    },
                }}
                InputProps={{
                    style: { color: colorTheme.theme.transform.black },
                }}
            />
            {
                // Conditionally render the visibility icon based on the password prop
                type === 'password' && (
                    inputType === 'email' ? (
                        <Visibility
                            onClick={() => setInputType('password')}
                            style={{ cursor: 'pointer', position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)', width: '17px', color: colorTheme.theme.text.lightGrey }}
                        />
                    ) : (
                        <VisibilityOff
                            onClick={() => setInputType('email')}
                            style={{ cursor: 'pointer', position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)', width: '17px', color: colorTheme.theme.text.lightGrey }}
                        />
                    )
                )
            }
        </Box>
    );
};

export default InputField;