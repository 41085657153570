
import { Amplify } from 'aws-amplify'
import _ from 'lodash';
import awsExports from "./../aws-exports";
import { getUserCredentials, verifyCustomerDb } from './../services/db-service';

let userData;

// Function to check if the URL contains the company name
const urlHasCompanyName = (url) => {
    console.log('url is: ', url)
    // HACK include amplify url in this check for now, no company name validation
    // todo company name validation (etc no norwegian characters)
    if (url.includes('localhost')) {
        return !url.startsWith('localhost');
    }
    else if (url.includes('transform-norway.no')) {
        return !url.startsWith('transform-norway.no');
    }
    else if (url.includes('staging.transforminsight.com')) {
        return !url.startsWith('staging.transforminsight.com');
    }
    else if (url.includes('development.transforminsight.com')) {
        return !url.startsWith('development.transforminsight.com');
    }
    else if (url.includes('qa.transforminsight.com')) {
        return !url.startsWith('qa.transforminsight.com');
    }
    else if (url.includes('transforminsight.com')) { //prod
        return !url.startsWith('transforminsight.com');
    }
    else if (url.includes('d2t7jtpq82eiuk.amplifyapp.com')) { //dev
        return !url.startsWith('d2t7jtpq82eiuk.amplifyapp.com');
    }
    else if (url.includes('d26gsired79q7l.amplifyapp.com')) { //qa
        return !url.startsWith('d26gsired79q7l.amplifyapp.com');
    }
    else if (url.includes('dq94uyu7xncme.amplifyapp.com')) { //staging
        return !url.startsWith('dq94uyu7xncme.amplifyapp.com');
    } else if (url.includes('transforminsight.com')) { //main entry
        return !url.startsWith('transforminsight.com');
    }
    return false;
};



// Function to fetch user credentials
const fetchData = async (company) => {
    const data = await getUserCredentials(company);
    userData = data;
    console.log('data credentials: ', data);

    if (!_.isEmpty(data)) {
        let exports = {
            ...awsExports,
            aws_user_pools_web_client_id: data.userPoolClientId.S,
            aws_user_pools_id: data.userPoolId.S,
        };
        Amplify.configure(exports);
        return true;
    }
    return false;
};

// Function to verify customer data
const verifyData = async (verifyObject) => {
    const verifyData = await verifyCustomerDb(verifyObject)
    console.log('verifyData', verifyData)
}

// Function to create a verify customer object
const createVerifyCustomerObject = (plainurl) => {
    const urlParts = plainurl.split('&')
    return {
        clientId: urlParts[1].split('=')[1],
        userName: urlParts[2].split('=')[1],
        confirmationCode: urlParts[3].split('=')[1]
    }
}

// Function to get user credentials
export const getCreds = async () => {
    console.log('process.env: ', process.env)
    const path = window.location.pathname;
    const host = window.location.host;
    const plainurl = window.location.href

    let company;

    console.log('pathname: ', window.location.pathname)
    if (path.includes('signin')) {
        console.log('path is signin test')
        if (plainurl.includes('clientId')) {
            let verifyObject = createVerifyCustomerObject(plainurl)
            verifyData(verifyObject)
        }
        if (urlHasCompanyName(host)) {
            company = host.substring(0, host.indexOf('.'));
            console.log('company: ', company);
            if (company) {
                // todo redirect to /
                return await fetchData(company);
            }
        }

    } else if (path.includes('register')) {
        console.log('path is register')
        return true;

    } else if (process.env.REACT_APP_DEV_ENV) {
        // Allows access to development, staging and qa data
        // Set REACT_APP_DEV_ENV in .env for local build and in amplify env
        // for hosted amplify app
        company = process.env.REACT_APP_DEV_ENV;
        console.log('Dev env', company)
        return await fetchData(company);
    }

    else if (urlHasCompanyName(host)) {
        company = host.substring(0, host.indexOf('.'));
        console.log('company name in front of url, company is: ', company);
        if (company) {
            return await fetchData(company);
        }
    }
    return false;
};


