import React, { useCallback, useEffect, useState } from 'react';
import './reminders-container.scss';
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { Chip, TextField } from '@material-ui/core';
import { ACTIVE_STATUS_COLUMN, RESPONDENT_STATUS } from "../../../assets/constants";
import { Box } from '@mui/material';
import { colorTheme } from '../../../assets/theme';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PublishIcon } from "../../../assets/images/icons/survey/publish-icon.svg";
import TextEditor from "./text-editor";
import PrimaryButton from '../../buttons/primary-button';
import _ from 'lodash';
import {
    sendReminders,
    resetErrors,
    updateCurrentSurvey,
    getRespondents
} from "../../../redux/actions";
import {
    REMINDER
} from "../../../assets/constants";

// Constants
const INPUT_COMPANY = 'INPUT_COMPANY';
const INPUT_SUBJECT = 'INPUT_SUBJECT';
const BOUNCED = 'BOUNCED';
const NOT_STARTED = 'NOT STARTED';
const NOT_SENT = 'NOT-SENT';
const SENDING_ERROR = 'SENDING_ERROR';

// Status map
const statusMap = {
    'NOT-SENT': { color: colorTheme.theme.colors.orange, backgroundColor: '#FF973A12' },
    'BOUNCED': {color: colorTheme.theme.app.red, backgroundColor: '#E23D3212'},
    'NOT STARTED': { color: colorTheme.theme.colors.orange, backgroundColor: '#FF973A12' },
    'IN PROGRESS': { color: colorTheme.theme.colors.lightBlue, backgroundColor: '#3288D812' },
    'SUBMITTED': { color: colorTheme.theme.colors.successGreen, backgroundColor: '#4BC26512' },
};

// Styles
const useStyles = makeStyles({
    textField: {
        width: '100%',
        maxWidth: '320px',

        '& label': {
            color: colorTheme.theme.text.lightGrey, // Default color
        },
        '& label.Mui-focused': {
            color: colorTheme.theme.transform.darkBlueTwo, // Hovered color
        },
        '& label.Mui-focused': {
            color: colorTheme.theme.transform.darkBlueTwo, // Focused color
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid' + colorTheme.theme.text.lightGrey, // Default color
            opacity: 0.42, // Default opacity
            transition: 'all 0.3s ease-in-out'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid' + colorTheme.theme.transform.darkBlueTwo, // Hovered color
            opacity: 1, // Hovered opacity
            transition: 'all 0.3s ease-in-out'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid' + colorTheme.theme.transform.darkBlueTwo, // Hovered color
            transition: 'none'
        },
        '& .MuiInputBase-input': {
            color: colorTheme.theme.text.mediumGrey, // Default color
        },
        '& .MuiInputBase-input::placeholder': {
            color: colorTheme.theme.text.mediumGrey, // Placeholder color
            opacity: 1, // Placeholder opacity
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#d32f2f !important'
        },
        '& .Mui-error:after': {
            borderBottomColor: '#d32f2f !important'
        },
        '& .Mui-disabled': {
            '&::placeholder': {
                color: colorTheme.theme.text.lightGrey, // Placeholder color
            },
        },
    },
});

// RemindersContainer component
function RemindersContainer(props) {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const { enqueueSnackbar } = useSnackbar(); // Get snackbar function from notistack 
    const currentSurvey = useSelector(state => state.currentSurvey); // Get current survey from redux
    const reminderSent = useSelector(state => state.reminderSent) // Get reminder sent status from redux
    const [checkedGroups, setCheckedGroups] = useState([]); // Set checked groups
    const [sendingReport, setSendingReport] = useState({}); // Set sending report
    const classes = useStyles(); // Custom styles

    // Set reminder from name
    const [reminderFromName, setReminderFromName] = useState({
        value: 'survey@development.transforminsight.com',
        error: false,
        errorMessage: '',
    });

    // Set reminder subject
    const [reminderSubject, setReminderSubject] = useState({
        value: 'Survey Conduct',
        error: false,
        errorMessage: '',
    });

    // Set reminder text
    const [reminderText, setReminderText] = useState('');
    
    // Function to handle send reminders
    const handleSendReminders = () => {
        const fromNameValid = validateInput(reminderFromName.value, INPUT_COMPANY);
        const subjectValid = validateInput(reminderSubject.value, INPUT_SUBJECT);
        const reminderEmail = validateReminderEmail();
        if (fromNameValid && subjectValid && reminderEmail) {
            const updateObject = {
                reminderFrom: reminderFromName.value,
                reminderSubject: reminderSubject.value,
                reminderBody: reminderText,
                reminderGroups: revertChangedFields(),
                templateName: 'reminder-template.html'
            }
            dispatch(sendReminders(currentSurvey.id, updateObject))
            openSnackbar('Reminder sent successfully', 'success');
        } else if (!reminderEmail) {
            openSnackbar('Reminder email must contain survey link!', 'error');
        }
    }
    
    // useEffect to get respondents
    useEffect(() => {
        (async () => {
            dispatch(getRespondents(currentSurvey.id));
        })()
        if (currentSurvey.lastReminder) {
            setReminderText(currentSurvey.lastReminder)
        }
        if (currentSurvey.reminderFromName) {
            setReminderFromName({ ...reminderFromName, value: currentSurvey.reminderFromName })
        }
        if (currentSurvey.reminderSubject) {
            setReminderSubject({ ...currentSurvey, value: currentSurvey.reminderSubject })
        }
    }, []);

    // useEffect to get respondents status
    useEffect(() => {
        if (currentSurvey && currentSurvey.respondents.data) {
            const respondentsStatusList = currentSurvey.respondents.data.map(r => r[ACTIVE_STATUS_COLUMN.field]);
            console.log('Respondent statuses list: ', respondentsStatusList)
            const mappedStatuses = {
                [RESPONDENT_STATUS.NOT_SENT]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.NOT_SENT).length,
                // [RESPONDENT_STATUS.SENDING_ERROR]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.SENDING_ERROR).length,
                [RESPONDENT_STATUS.EMAIL_BOUNCED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.EMAIL_BOUNCED).length,
                [RESPONDENT_STATUS.SENT]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.SENT).length,
                // [RESPONDENT_STATUS.REMINDED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.REMINDED).length,
                [RESPONDENT_STATUS.STARTED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.STARTED).length + respondentsStatusList.filter(s => s === RESPONDENT_STATUS.REMINDED_AFTER_STARTED).length,
                [RESPONDENT_STATUS.SUBMITTED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.SUBMITTED).length,
            }
            setSendingReport(Object.values(mappedStatuses))
        }
    }, [currentSurvey])

    // useEffect to get reminder sent status
    useEffect(() => {
        console.log('reminders sent component ', reminderSent )
        if (reminderSent) {
            dispatch(getRespondents(currentSurvey.id));
            setCheckedGroups([])
        }
    }, [reminderSent])

    // Function to debounce update
    const delayedUpdate = useCallback(_.debounce(updatedSurvey =>
        dispatch(updateCurrentSurvey(updatedSurvey)), 300), []);

    // Function to open snackbar
    const openSnackbar = (msg, variant) => {
        enqueueSnackbar(msg, {
            variant: variant,
        });
        dispatch(resetErrors());
    };
    
    // Function to filter respondent statuses
    const filterRespondentStatuses = () => {
        let orderedStatuses = { 'NOT-SENT': '', 'EMAIL_BOUNCED': '', 'SENT': '', 'STARTED': '', 'SUBMITTED': '' };
        
        let preparedStatuses = { ...RESPONDENT_STATUS };
        Object.keys(preparedStatuses).forEach(key => {
            switch (true) {
                case (key === 'DO_NOT_SEND' || key === 'REMINDED_AFTER_STARTED' || key === 'SENDING_ERROR' || key === 'REMINDED'):
                    delete preparedStatuses[key];
                    break;
                case (key === 'EMAIL_BOUNCED'):
                    preparedStatuses['EMAIL_BOUNCED'] = BOUNCED;
                    break;
                case (key === 'SENT'):
                    preparedStatuses['SENT'] = NOT_STARTED;
                    break;
                case (key === 'STARTED'):
                    preparedStatuses['STARTED'] = 'IN PROGRESS';
                    break;
                case (key === 'NOT_SENT'):
                    preparedStatuses['NOT-SENT'] = NOT_SENT
                    delete preparedStatuses['NOT_SENT']
                    break;
            }
        })
        return Object.values({ ...orderedStatuses, ...preparedStatuses });
    }

    // Function to check if option is checked
    const isChecked = (option) => {
        return checkedGroups.includes(option)
    }

    // Function to handle option check
    const handleOptionCheck = (value) => {
        let groups = [...checkedGroups];
        if (!groups.includes(value)) {
            groups.push(value);
        } else {
            groups.splice(groups.indexOf(value), 1)
        }
        setCheckedGroups(groups)
    }

    // Function to validate reminder email
    const validateReminderEmail = () => {
        return reminderText && reminderText.includes("{{{SurveyLink}}}");
    };

    // Function to handle change
    const handleChange = (value, inputType) => {
        if (inputType === INPUT_COMPANY) {
            validateInput(value, INPUT_COMPANY);
            delayedUpdate({ ...currentSurvey, reminderFromName: value });
        } else if (inputType === INPUT_SUBJECT) {
            validateInput(value, INPUT_SUBJECT);
            delayedUpdate({ ...currentSurvey, reminderSubject: value });
        }
    };

    // Function to validate input
    const validateInput = (value, inputType) => {
        if (!value || value === '') {
            if (inputType === INPUT_COMPANY) {
                setReminderFromName({ ...reminderFromName, value: value, error: true, errorMessage: 'required' });
            } else if (inputType === INPUT_SUBJECT) {
                setReminderSubject({ ...reminderSubject, value: value, error: true, errorMessage: 'required' });
            }
            return false;
        } else {
            if (inputType === INPUT_COMPANY) {
                setReminderFromName({ ...reminderFromName, value: value, error: false, errorMessage: '' });
            } else if (inputType === INPUT_SUBJECT) {
                setReminderSubject({ ...reminderSubject, value: value, error: false, errorMessage: '' });
            }
        }
        return true;
    };

    // Function to handle reminder text
    const handleReminderText = (reminderText) => {
        if (reminderText !== '') {
            setReminderText(reminderText)
        }
    }

    // Function to revert changed fields
    const revertChangedFields = () => {
        let backendStatuses = [...checkedGroups]
        for (let i of backendStatuses) {
            if (i === BOUNCED) {
                backendStatuses[backendStatuses.indexOf(i)] = 'EMAIL_BOUNCED';
            } if (i === NOT_STARTED) {
                backendStatuses[backendStatuses.indexOf(i)] = 'SENT'
            } if (i === 'IN PROGRESS') {
                backendStatuses[backendStatuses.indexOf(i)] = 'STARTED'
            }
        }
        return backendStatuses
    }

    return (
        <Box className='reminders-container'>
            <Box className='chip-container'>
                {
                    filterRespondentStatuses(RESPONDENT_STATUS).map((item, index) => {
                        const statusInfo = statusMap[item] || { color: colorTheme.theme.transform.darkBlueTwo };
                        console.log('status info: ', statusInfo)
                        return (
                            <PrimaryButton 
                            key={index}
                            text={`${item} (${sendingReport[index]})`} 
                            color={isChecked(item) ? statusInfo.color : statusInfo.backgroundColor}
                            hoverColor={isChecked(item) ? statusInfo.color : statusInfo.backgroundColor}
                            onClick={() => handleOptionCheck(item)} 
                            border={'0px 0px 0px 1px inset' + statusInfo.color} 
                            borderHover={'0px 0px 0px 1px inset' + statusInfo.color} 
                            customTextColor={isChecked(item) ? 'white' : statusInfo.color}
                            />
                        )
                    })
                }
            </Box>
            <Box className="form" gap={'20px'}>
                <TextField className='input' label="From"
                    classes={{ root: classes.textField }}
                    value={reminderFromName.value ? reminderFromName.value : ''}
                    onChange={(e) => handleChange(e.target.value, INPUT_COMPANY)}
                    error={reminderFromName.error}
                    helperText={reminderFromName.errorMessage}
                    disabled
                />
                <TextField className='input' label="To"
                    classes={{ root: classes.textField }}
                    placeholder={checkedGroups.length > 0 ? checkedGroups.toString() : 'Select group'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled
                />
                <TextField className='input' label="Subject"
                    classes={{ root: classes.textField }}
                    value={reminderSubject.value}
                    onChange={(e) => handleChange(e.target.value, INPUT_SUBJECT)}
                    error={reminderSubject.error}
                    helperText={reminderSubject.errorMessage}
                    placeholder='Survey Conduct'
                />
            </Box>
            <Box className='edit-email'>
                <TextEditor source={REMINDER} handleReminderText={handleReminderText} />
            </Box>
            <Box className='send-reminders'>
                <PrimaryButton text={currentSurvey.startImmediately ? 'Send' : 'Schedule Survey'} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} Icon={PublishIcon} onClick={handleSendReminders} />
            </Box>
        </Box>
    )
}

const RespondentsStatus = ({ status }) => {
    return (
        <Box className='respondent-status'>
            {status}
        </Box>
    )
}

export default RemindersContainer
