export function convertToLocalTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleString();
}

export function formatDate(isoString, time) {
    const date = (new Date(isoString)).toLocaleString();

    const [datePart, timePart] = date.split(',');
    const formattedDate = datePart.split('/').join('.');

    if (time) {
        let [hours, minutes] = timePart.trim().split(':');
        hours = parseInt(hours);
        let period = 'AM';

        if (hours === 0) {
            hours = 12;
        } else if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                hours -= 12;
            }
        }

        return `${formattedDate} ${hours}:${minutes} ${period}`;
    }
    return formattedDate;
}