import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { updateQuestion } from "../../../redux/actions";
import { mapMenuItems, getListName } from "../../../pages/survey-page/edit-tab/questions-tab/list-utils";
import { updateRelationshipQuestions } from "../../../services/relationship-question-service";
const LIST = 'LIST';

const useSearchChooseQuestion = (template) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    // State to store mapped columns for the selected list
    const [mappedColumns, setMappedColumns] = useState([]);

    // State to store the selected list ID
    const [selectedList, setSelectedList] = useState('');

    // State to store the selected columns from the list
    const [selectedColumn, setSelectedColumn] = useState([]);

    // State to store all the available lists
    const [mappedLists, setMappedLists] = useState([]);

    // State for the maximum number of allowed answers
    const [textField, setTextField] = useState(6);

    // State to store the checkbox selections for required fields
    const [checkBox, setCheckBox] = useState({});

    // State to store the item name field
    const [itemName, setItemName] = useState([]);


    // Switch state for enabling/disabling max number of answers
    const [maxNumSwitch, setMaxNumSwitch] = useState(true);

    // Switch state for allowing/disallowing user-provided answers
    const [otherAllowedSwitch, setOtherAllowedSwitch] = useState(true);

    // State to store options for autocomplete functionality
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);

    let mappedList = [];

    // Function to handle new list uploads and update relevant states
    const handleListUploaded = (newList) => {
        const updatedMappedLists = [...mappedLists, newList];
        setMappedLists(updatedMappedLists);
        setSelectedList(newList.id);
        mapListColumns(newList.id);
        setSelectedColumn([]);
        setCheckBox({});
        setItemName('');

        updateSearchChooseQuestion({
            items_source_type: LIST,
            items_source: newList.title,
            items_source_id: newList.id,
            item_fields: [],
            required_fields: [],
            items_name_field: ''
        });
    };

    // Effect to initialize default values if not provided in the template
    useEffect(() => {
        let updateObject = {};
        if (template.item_other_allowed === undefined) {
            updateObject['item_other_allowed'] = false;
        }
        updateSearchChooseQuestion(updateObject);
    }, []);

    // Effect to map lists and initialize state from the template
    useEffect(() => {
        if (currentSurvey.lists) {
            setMappedLists(mapMenuItems(currentSurvey, mappedList, 'custom'));
        }
        if (currentSurvey.respondents) {
            setMappedLists(mapMenuItems(currentSurvey, mappedList, 'respondents'));
        }

        if (template.items_source_id) {
            let list = getListName(template.items_source_id, mappedList);
            if (list) {
                setSelectedList(template.items_source_id);
                mapListColumns(template.items_source_id);
            }
            if (template.items) {
                setAutocompleteOptions(template.items);
            }
            if (template.item_fields) {
                setSelectedColumn(template.item_fields);
            }
            if (template.required_fields.length > 0 && selectedColumn.length > 0) {
                checkBoxStateObjectInit(template.required_fields, true);
            }
            if (template.items_name_field) {
                setItemName(prevItemName => [...new Set([...prevItemName, ...template.items_name_field])]);
            }
        }

        if (template.item_max_number !== undefined) {
            setMaxNumSwitch(template.item_max_number);
            setTextField(template.items_max_number);
        }
        if (template.items_max_number) {
            setTextField(template.items_max_number);
        }
        if (template.item_other_allowed !== undefined) {
            setOtherAllowedSwitch(template.item_other_allowed);
        }
    }, [template]);

    // Effect to update the preview whenever selected columns or selected list changes
    useEffect(() => {
        populatePreviewSearch();
        console.log('selectedColumn', selectedColumn);

    }, [selectedColumn]);



    // Debounced function to update the question in the store
    const delayedUpdate = useCallback(
        _.debounce(question => dispatch(updateQuestion(currentSurvey.id, question)), 1000),
        [dispatch, currentSurvey.id]
    );

    // Function to update the question with new values
    const updateSearchChooseQuestion = value => {
        let newQuestion = _.cloneDeep(template);
        const newObject = Object.assign(newQuestion, value);
        delayedUpdate(newObject);
    };

    // Function to map columns for the selected list
    const mapListColumns = value => {
        let columnNames = [];
        let resultList = mappedLists.find(({ id }) => id === value);
        if (resultList !== undefined) {
            resultList.columns.forEach(list => {
                if (list.title !== 'tableData' && list.title !== 'Status') {
                    columnNames.push(list.title);
                }
            });
        } else {
            if (currentSurvey.respondents) {
                currentSurvey.respondents['columns'].forEach(list => {
                    if (list.title !== 'tableData' && list.title !== 'Status') {
                        columnNames.push(list.title);
                    }
                });
            }
        }
        setMappedColumns(columnNames);
    };

    // Function to handle changes in the selected list
    const handleListSelectChange = ({ value }) => {
        setSelectedColumn([]);
        setCheckBox({});
        setItemName('');

        updateSearchChooseQuestion({
            items_source_type: LIST,
            items_source: getListName(value, mappedLists),
            items_source_id: value,
            item_fields: [],
            required_fields: [],
            items_name_field: []
        });
        setSelectedList(value);
        mapListColumns(value); // Ensure columns are mapped immediately
    };

    // Function to handle column selection changes
    const handleColumnSelect = value => {
        let nameField = '';

        nameField = value[0];
        console.log('nameField', nameField);

        updateSearchChooseQuestion({
            item_fields: value,
            items: autocompleteOptions,
            items_name_field: [nameField],
            required_fields: [value[0]]
        });

        setSelectedColumn(value);
        populatePreviewSearch(); // Update preview immediately
    };

    // Function to populate the preview with selected columns
    const populatePreviewSearch = () => {
        autocompleteOptions.length = 0;
        let resultList = mappedLists.find(({ id }) => id === selectedList);

        if (resultList && selectedColumn) {
            resultList.data.forEach((dataItem, index) => {
                let tmpObject = { item_id: 'item_id_' + index };
                selectedColumn.forEach(columnItem => {
                    tmpObject[columnItem] = dataItem[columnItem] || undefined;
                });
                autocompleteOptions.push(tmpObject);
            });
        }
    };

    // Function to initialize the checkbox state object
    const checkBoxStateObjectInit = (checkboxes, checked) => {
        let tmpObj = {};
        checkboxes.forEach(item => {
            tmpObj[item] = checked;
        });
        setCheckBox(tmpObj);
    };

    // Function to handle the selection of the name field
    const handleNameChoose = (columnItem) => {
        console.log('columnItem', itemName);
        let updatedItemName = [...itemName].filter(item => item !== null && item !== undefined); // Clean initial list
        if (itemName.includes(columnItem)) {
            // Only allow deselection if more than one item is selected
            if (updatedItemName.length > 1) {
                updatedItemName = updatedItemName.filter(item => item !== columnItem);
            }
        } else {
            if (columnItem !== null && columnItem !== undefined) { // Ensure no undefined or null values are added
                updatedItemName.push(columnItem);
            }
        }

        setItemName(updatedItemName);
        updateSearchChooseQuestion({ items_name_field: updatedItemName });
        updateRelationshipQuestions(currentSurvey.id, template.id, updatedItemName, currentSurvey.questions);
    };


    return {
        handleListUploaded,
        selectedList,
        mappedLists,
        handleListSelectChange,
        selectedColumn,
        handleColumnSelect,
        mappedColumns,
        itemName,
        handleNameChoose,
        textField,
        maxNumSwitch,
        setMaxNumSwitch,
        otherAllowedSwitch,
        setOtherAllowedSwitch,
        checkBox,
        advancedSettings: template.advancedSettings,
        handleAdvancedView: template.handleAdvancedView,
        autocompleteOptions
    };
};

export default useSearchChooseQuestion;
