import React, { useState, useEffect } from 'react';
import { Box, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { ReactComponent as ExportIcon } from '../../assets/images/actions/icon-export.svg';
import { ReactComponent as DragIcon } from '../../assets/images/actions/icon-drag.svg';
import { colorTheme } from '../../assets/theme';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextButton from '../buttons/text-button';
import CustomCheckboxLabel from '../form/custom-checkbox-label';

// Style for the component using makeStyles
const useStyles = makeStyles(() => ({
    menuItem: {
        color: colorTheme.theme.transform.darkBlueTwo,
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',

        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: 'transparent',
        },
    },
}));

// ColumnsMenu component
const ColumnsMenu = ({ columns, onColumnOrderChange, onColumnSelectionChange, selectedColumns }) => {
    const classes = useStyles(); // Get styles from useStyles
    const [anchorEl, setAnchorEl] = useState(null); // State for anchor element
    const [columnsState, setColumnsState] = useState(columns); // State for columns
    const open = Boolean(anchorEl); // Boolean state for anchor element

    useEffect(() => {
        setColumnsState(columns); // Update columnsState when columns prop changes
    }, [columns]);

    // Function to handle click event
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to handle close event
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to handle drag end event
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedColumns = Array.from(columnsState);
        const [removed] = reorderedColumns.splice(result.source.index, 1);
        reorderedColumns.splice(result.destination.index, 0, removed);

        setColumnsState(reorderedColumns); // Set reordered columns
        onColumnOrderChange(reorderedColumns.map(column => column.field)); // Call onColumnOrderChange with reordered columns
    };

    // Function to handle select all event
    const handleSelectAll = () => {
        const allSelected = selectedColumns.length === columnsState.length;
        onColumnSelectionChange(allSelected ? [] : columnsState.map(column => column.field));
    };

    // Function to handle checkbox change event
    const handleCheckboxChange = (columnField) => {
        onColumnSelectionChange(
            selectedColumns.includes(columnField)
                ? selectedColumns.filter(field => field !== columnField)
                : [...selectedColumns, columnField]
        );
    };

    return (
        <Box>
            <TextButton name='Columns' onClick={handleClick} size='small' fontSize='14px' width='auto' margin='auto auto 0 auto' borderRadius='23px' Icon={ExportIcon} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2.5px 0px #7B7D8982',
                        marginTop: '45px',
                    },
                }}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="columns">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <MenuItem onClick={null} className={classes.menuItem} TouchRippleProps={{ style: { display: 'none' } }}>
                                    <CustomCheckboxLabel
                                        value='all'
                                        label='Show all'
                                        checked={selectedColumns.length === columnsState.length}
                                        onChange={handleSelectAll}
                                    />
                                </MenuItem>
                                {columnsState && columnsState.map((column, index) => (
                                    <Draggable key={column.field} draggableId={column.field} index={index}>
                                        {(provided) => (
                                            <MenuItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.menuItem}
                                                TouchRippleProps={{ style: { display: 'none' } }}
                                            >
                                                <CustomCheckboxLabel
                                                    value={column.field}
                                                    label={column.title}
                                                    checked={selectedColumns.includes(column.field)}
                                                    onChange={() => handleCheckboxChange(column.field)}
                                                    cursor='grab'
                                                />
                                                <DragIcon />
                                            </MenuItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Menu>
        </Box>
    );
};

export default ColumnsMenu;
