import React from "react";
import './app-card.scss';
import { useHistory } from "react-router-dom";

const AppCard = ({ title, icon, description, link }) => {
    const history = useHistory(); // This is a hook that allows us to navigate between pages

    // This function will be called when the card is clicked
    const handleClick = () => {
        history.push(link);
    }

    return (
        <div className="app-card" onClick={handleClick}>
            <div className="app-card-header">
                <div className="app-card-icon">
                    {icon}
                </div>
                <div className="app-card-description">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default AppCard;