import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateRespondentErrors } from "../../redux/actions";
import { emailRegEx } from "../../utils/regex-utils";
import { ROW_ID, STATUS_COLUMN, ACTIVE_STATUS_COLUMN } from "../../assets/constants";
import _ from "lodash";

const uuid = require('uuid'); // Generate unique IDs

// Constants
const DUPLICATE_ENTRY = 'DUPLICATE ENTRY';
const INVALID_EMAIL = 'INVALID EMAIL';
const EMAIL_NULL = 'EMAIL EMPTY';
const RESPONDENT_OK = 'RESPONDENT OK';

// Function to check if a value is null
const isNull = (value) => {
    return value === null || value === undefined || value === '';
};

// Function to validate respondents
export const useValidateRespondnts = (currentSurvey) => {
    const dispatch = useDispatch(); // Dispatch actions
    const stateRespondentErrors = useSelector(state => state.respondentErrors); // Get respondent errors
    const [respondentList, setRespondentList] = useState(null); // Respondent list
    const [respondentErrors, setRespondentErrors] = useState(stateRespondentErrors ? stateRespondentErrors : 0); // Respondent errors

    useEffect(() => {
        if (currentSurvey && currentSurvey.respondents && !_.isEqual(currentSurvey.respondents, respondentList)) {
            validate(currentSurvey.respondents);
        }
    }, [currentSurvey]);

    // Update respondent errors when the state changes
    useEffect(() => {
        dispatch(updateRespondentErrors(respondentErrors));
    }, [respondentErrors, dispatch]);

    // Function to validate respondents
    const validate = (respondentsData) => {
        if (respondentsData.length === 0) {
            setRespondentList({
                columns: respondentList ? respondentList.columns : [],
                data: []
            });
            return;
        }
        let header = _.cloneDeep(respondentsData.columns);
        // if (!header.find(col => col.field === STATUS_COLUMN.field)) {
        //     header = [STATUS_COLUMN, ...header];
        // }

        // Add active status column if not present
        if (!header.find(col => col.field === ACTIVE_STATUS_COLUMN.field)) {
            header = [ACTIVE_STATUS_COLUMN, ...header];
        }

        // Default sort header by active status, name, email, and other columns
        header = _.sortBy(header, [
            (col) => col.field.toLowerCase() === ACTIVE_STATUS_COLUMN.field.toLowerCase() ? 0 :
                col.field.toLowerCase() === 'name' ? 1 :
                    col.field.toLowerCase() === 'email' ? 2 : 3
        ]);

        const respondents = _.cloneDeep(respondentsData.data);
        const respondentEmails = respondents.map(r => r.email);
        let respondentErrorCounter = 0;
        respondents.forEach(respondent => {
            if (!respondent[ROW_ID]) {
                respondent[ROW_ID] = uuid.v4();
            }
            if (!respondent[ACTIVE_STATUS_COLUMN.field]) {
                respondent[ACTIVE_STATUS_COLUMN.field] = 'NOT-SENT';
            }

            for (const key in respondent) {
                if (respondent[key] === '') {
                    respondentErrorCounter++;
                }
            }

            const respondentEmail = respondent.email;
            if (isNull(respondentEmail)) {
                respondent[STATUS_COLUMN.field] = EMAIL_NULL;
                respondentErrorCounter++;
            } else if (!emailRegEx.test(respondentEmail)) {
                respondent[STATUS_COLUMN.field] = INVALID_EMAIL;
                respondentErrorCounter++;
            } else if (_.findIndex(respondentEmails, e => e === respondentEmail) !== _.findLastIndex(respondentEmails, e => e === respondentEmail)) {
                respondent[STATUS_COLUMN.field] = DUPLICATE_ENTRY;
                respondentErrorCounter++;
            } else {
                respondent[STATUS_COLUMN.field] = RESPONDENT_OK;
            }
        });

        setRespondentErrors(respondentErrorCounter);
        setRespondentList({
            columns: header,
            data: respondents
        });
    };
};