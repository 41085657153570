import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Function to check if a user has access based on their licenses
function hasAccess(user, licenses) {
    // Return true if every license in the licenses array is present and set to "true" in the user object
    console.log('user is: ', user);
    console.log('licenses is: ', licenses);
    return licenses.every(license => user && user[license] === "true");
}

function isItOwner(user) {
    return user && user['role'] === 'owner';
}

// Component to protect routes based on user licenses
const ProtectedRoute = ({ component: Component, licenses, ...rest }) => {
    // Get the user data from the redux store
    const user = useSelector(state => state.userData);
    // State to track if the user data is ready
    const [isReady, setReady] = useState(false);

    // Effect to set isReady to true once the user data is available
    useEffect(() => {
        console.log(user);
        // Check if user data exists and is not empty
        if (user && Object.keys(user).length > 0) {
            setReady(true);
        }
    }, [user]); // Re-run the effect when the user data changes

    // If the user data is not ready, show a loading spinner or placeholder
    if (!isReady) {
        return <div>Loading...</div>;
    }

    // Render the Route component
    return (
        <Route {...rest} render={props => (
            // If the licenses array is empty, only the Owner has access
            // If the licenses array is not empty, check if the user has the necessary licenses
            user && (isItOwner(user) || (licenses.length > 0 && hasAccess(user, licenses))) ?
                <Component {...props} userTemplate={user} /> :
                <Redirect to="/unauthorized" />
        )} />
    );
};

// Export the ProtectedRoute component
export default ProtectedRoute;
