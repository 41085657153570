import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Box, Menu, MenuItem, ListItemIcon, IconButton } from '@material-ui/core'
import './account-menu.scss'
import { ReactComponent as ProfileIcon } from "../assets/images/menu/header/profile-icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/menu/header/logout-icon.svg";
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';



// AccountMenu component to render the account menu in the header
function AccountMenu() {
    const history = useHistory(); // Get the history object from the useHistory hook
    const [anchorEl, setAnchorEl] = useState(null); // State to store the anchor element of the menu
    const open = !!anchorEl; // Boolean value to check if the menu is open or not
    const userData = useSelector(state => state.userData); // Access user data from Redux store.

    // Function to handle the click event of the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to handle the close event of the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to handle the logout event
    const handleLogout = () => {
        localStorage.clear()
        history.replace('/signin')
    }

    const navigateToProfile = () => {
        history.push('/profile');
    };

    useEffect(() => {
        console.log('userData:', userData)
    }
        , [userData]);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className='avatar'>
                {/* <Tooltip title="Account settings">
                    
                </Tooltip> */}
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                    <Avatar alt="Profile Picture" src={userData.picture} sx={{ width: 32, height: 32 }} />
                    {/* profileCapitals */}
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                getContentAnchorEl={null}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                className='account-menu'
            >
                <MenuItem onClick={navigateToProfile}>
                    <ListItemIcon>
                        <ProfileIcon />
                    </ListItemIcon>
                    My Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default AccountMenu