import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as DemoTemplateIcon } from '../../../assets/images/survey-dashboard/blank-survey-icon.svg';
import { ReactComponent as BaseTemplateIcon } from '../../../assets/images/survey-dashboard/base_template.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HeaderDialog from './../../../components/dialogs/header-dialog';
import './../../../components/dialogs/create-new-survey.scss';
import { createFromTemplate } from "../../../redux/actions";
import { Box, Typography, Fab } from '@mui/material';

import TemplateDetails from '../../../assets/template-details';
import BaseTemplate from '../../../assets/base-template';
import DemoTemplate from '../../../assets/base-template-no';


const ACTION_START_SURVEY_BASE = 'ACTION_START_SURVEY_BASE';
const ACTION_START_SURVEY_DEMO = 'ACTION_START_SURVEY_DEMO';

const TemplateView = ({ open, handleClose }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleActionClick = (action) => {
        setLoading(true);
        if (action === ACTION_START_SURVEY_DEMO) {
            dispatch(createFromTemplate(TemplateDetails, DemoTemplate)).then(() => {
                setLoading(false);
                history.push(`/survey/edit`);
            });
        }

        if (action === ACTION_START_SURVEY_BASE) {
            dispatch(createFromTemplate(TemplateDetails, BaseTemplate)).then(() => {
                setLoading(false);
                history.push(`/survey/edit`);
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='create-new-survey-dialog'
        >
            <HeaderDialog handleClose={handleClose} title={'Create new template'} />
            <DialogContent>
                {loading &&
                    <Box width={400} height={200} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography mb={3} className='loading-text'>Creating your survey ...</Typography>
                        <CircularProgress className='loader' />
                    </Box>
                }

                {!loading &&
                    <Box className="create-new-survey-dialog-types">
                        <CreateNewSurveyType
                            Icon={DemoTemplateIcon}
                            title={'DEMO'}
                            description={'Build your own survey starting from a blank sheet.'}
                            onClick={() => handleActionClick(ACTION_START_SURVEY_DEMO)}
                        />
                        <CreateNewSurveyType
                            Icon={BaseTemplateIcon}
                            title={'BASE'}
                            description={'Select a pre-designed layout to quickly customize your survey.'}
                            onClick={() => handleActionClick(ACTION_START_SURVEY_BASE)}
                        />
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
};

const CreateNewSurveyType = ({ Icon, title, description, onClick }) => {
    return (
        <Fab className="create-new-survey-dialog-type" onClick={onClick}>
            <Icon className="survey-tile-icon" />
            <Typography variant="h3" className="survey-tile-title">{title}</Typography>
            <Typography variant="body2" className="survey-tile-description">{description}</Typography>
        </Fab>
    );
};

export default TemplateView;
